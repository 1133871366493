import { DialogService } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { PaginationHandler } from "elements/pagination-handler";
import { PubSub } from "lib/event/PubSub";
import { DataFilter } from "lib/tables/DataFilter";
import { ServiceInvoiced as ServiceInvoicedDto } from 'models';
import { ErrorService } from "services";
import { Router } from 'aurelia-router';
import { ServiceInvoiceService } from "services/service-invoice-service";
import { ServiceInvoicePreview } from "./service-invoice-preview";
import { Prompt } from "elements/prompt";
import { ServiceDialog } from "components/service-dialog/service-dialog";
import { ServiceStationService } from "services/service-station-service";
import { CustomerService } from "services/customer-service";
import { getCallbackData } from "elements/filterer";
import { Filters, filterTypes } from "elements/Filter";

type TableFilter = {
    serviceStationId?: number;
    customerIds?: string[];
    inProgress?: boolean;
    invoiceable?: boolean;
    invoiced?: boolean;
  } & DataFilter;

type TableHeader<T> = {
    key: Partial<keyof T>
    label: string;
    sortable: boolean
}

@autoinject
export class ServiceInvoiceInvoiceable {

    protected invoiceableInvoices: ServiceInvoicedDto[] = [];

    protected dataFilter: TableFilter = new DataFilter();

    protected tableHeaders: TableHeader<ServiceInvoicedDto & { actions: '' }>[] = [
        { key: 'Id', label: 'order.orderNumber', sortable: true },
        { key: 'ServiceId', label: 'general.id', sortable: true },
        { key: 'NetIdentifier', label: 'net.netidentifier', sortable: true },
        { key: 'CustomerName', label: 'general.customer', sortable: true },
        { key: 'ServiceStationName', label: 'general.servicestation', sortable: true },
        { key: 'CreatedBy', label: 'general.createdBy', sortable: true },
        { key: 'Created', label: 'general.created', sortable: true },
        { key: 'OrderDate', label: 'service.orderDate', sortable: true },
        { key: 'ReceivedDate', label: 'service.receivedDate', sortable: true },
        { key: 'Washing', label: 'service.wash', sortable: true },
        { key: 'Testing', label: 'service.test', sortable: true },
        { key: 'Reparation', label: 'service.reparation', sortable: true },
        { key: 'Impregnation', label: 'service.impregnation', sortable: true },
        { key: 'Spagetti', label: 'service.spagetti', sortable: true },
        { key: 'Packaging', label: 'service.packaging', sortable: true },
        { key: 'Delivery', label: 'service.delivery', sortable: true },
        { key: 'actions', label: '', sortable: false },
      ];

    constructor(
        private serviceInvoiceService: ServiceInvoiceService,
        private errorService: ErrorService,
        private pagination: PaginationHandler,
        private dialogService: DialogService,
        private i18n: I18N,
        private router: Router,
        private pubsub: PubSub,
        protected serviceStationService: ServiceStationService,
        protected customerService: CustomerService,
    ){}

    protected async bind() {
        this.setupPagination();

        this.pubsub.sub('invoice-result-generated', () => {
            void this.getInvoicesInProgress();
          });
      
          this.pubsub.sub('filter-section:reset-filters', (data) => {
            if (data.context !== this.context) return;
            this.filterClearAll();
          });
      
          this.pubsub.sub('filter-section:list-settings-changed', (data) => {
            if (this.dataFilter.top === data.settings.pageSize) return;
      
            this.dataFilter.top = data.settings.pageSize;
            this.pagination.pageSize = data.settings.pageSize;
            this.pagination.currentPage = 1;
            void this.getInvoicesInProgress();
          })
      
          this.pubsub.sub('filter-section:search-text-changed', (data) => {
            if (data.context !== this.context) return;
            this.dataFilter.searchText = data.searchText;
            void this.getInvoicesInProgress();
          });
      

        await this.getInvoicesInProgress();
    }

    protected setupPagination() {
        const routeParams = this.router.currentInstruction?.queryParams;
        let currentPage = 1;
        if (routeParams && routeParams.currentPage) {
            currentPage = +routeParams.currentPage;
        }

        this.pagination.init({
            currentPage: currentPage,
            totalItems: 0,
            onPageChanged: (_, pagination) => {
                this.dataFilter.skip = pagination.skip;
                this.dataFilter.top = pagination.top;
                void this.getInvoicesInProgress();
            },
        })
    }

    protected setOrderByColumn(column: string) {
        this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
        void this.getInvoicesInProgress();
      }

      protected async getInvoicesInProgress() {
        try {
            const filters = await this.filterGetFiltersQuery?.();

            this.dataFilter.serviceStationId = filters?.serviceStationId || null;
            this.dataFilter.customerIds = filters?.customerIds || [];
            this.dataFilter.inProgress = false;
            this.dataFilter.invoiceable = true;
            this.dataFilter.invoiced = false;
            this.dataFilter.locale = this.i18n.getLocale() as 'en' | 'nb' | 'es';
            const response = await this.serviceInvoiceService.allInvoices(this.dataFilter);
            if(!response) return;
            this.pagination.totalItems = +response.headers.get('x-total-count') || 0;
            this.invoiceableInvoices = response.data;
        } catch (error) {
            this.errorService.handleError(error)
        }
    }

    protected async resetInvoice(invoiceId: string){
        try {
            const shouldReset = await this.confirmResetOrder();
            if (!shouldReset) return;
            await this.serviceInvoiceService.resetInvoice(invoiceId);
            await this.getInvoicesInProgress();
            this.pubsub.publish('invoice-result-revoked', null);
            this.pubsub.publish('tabs-meta:update', null);
        } catch (error) {
            this.errorService.handleError(error);
        }
    }

    protected openDialog(data: number) {
        this.dialogService
          .open({
            viewModel: ServiceInvoicePreview,
            model: [data],
            lock: true,
            position: () => {},
          })
          .whenClosed(() => {
            document.querySelector('html').style.overflowY = null;
          })
          .catch(() => {
            document.querySelector('html').style.overflowY = null;
          });
      }

      protected openService(id: number, netId: number) {
        this.dialogService
          .open({
            viewModel: ServiceDialog,
            model: { Id: id, NetId: netId },
            lock: false,
            position: () => {},
          })
          .whenClosed(() => {
            void this.getInvoicesInProgress();
            document.querySelector('html').style.overflowY = null;
          })
          .catch(() => {
            document.querySelector('html').style.overflowY = null;
          });
      }

      protected async confirmResetOrder() {
        let reset = false;
        await this.dialogService
          .open({
            viewModel: Prompt,
            model: {
                header: 'general.deleteOrder',
                message: this.i18n.tr('general.deleteOrderConfirmation'),
              messagePreparsed: true,
              actions: {
                continue: { enabled: true, t: 'dialog.yes' },
                cancel: { enabled: true, t: 'dialog.cancel' },
              },
            },
          })
          .whenClosed((res) => {
            if (res.output === 'continue') {
              reset = true;
            }
          });
        return reset;
      }

    protected detached() {
        this.pubsub.unsub();
    }


    protected context = 'service_invoice_invoiceable';
    protected filterClearAll: () => void;
    protected filterClearSingle: (name: string) => void;
    protected filterToggleVisible: () => void;
    protected filterGetFiltersQuery: () => Promise<{ serviceStationId?: number; customerIds?: string[] }>;
    protected setFilterValueByName: (name: string, data: any) => void;

    protected defaultFilters = {
      serviceStationId: true,
      customerIds: true,
    };

    protected getFilterKey() {
      return 'SERVICE_INVOICE_INVOICEABLE_TABLE';
    }

    protected onFilterChanged() {
      void this.getInvoicesInProgress();
    }

    getFilterValues() {
      return getCallbackData(this.filterGetFiltersQuery);
    }

    protected async setupFilters() {
      return await new Promise((res: (v: Filters) => void) => {
        setTimeout(async () => {
          const serviceStationsJob = this.serviceStationService.getAll();
          const customersJob = this.customerService.getAllCached();
  
          const [customers, serviceStations] = await Promise.all([customersJob, serviceStationsJob]);
          const filteredServiceStations =
            serviceStations.sort((a, b) => (a.Name > b.Name ? 1 : -1)).filter((x) => x.CanGenerateInvoiceData) || [];
          const filteredCustomers = customers.filter((x) => !x.IsDeleted);
  
          const filters: Filters = {
            serviceStationId: {
              name: 'serviceStationId',
              label: this.i18n.tr('service.serviceStation'),
              type: filterTypes.RADIO,
              options: filteredServiceStations.map((x) => ({ Id: x.Id, Name: x.Name })),
            },
            customerIds: {
              name: 'customerIds',
              label: this.i18n.tr('general.customer'),
              type: filterTypes.CHECKBOX,
              options: filteredCustomers,
              query: 'customerIds',
            },
          };
  
          res(filters);
        });
      });
    }
}